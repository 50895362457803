import React, { useMemo } from "react"
import _PropTypes from "prop-types"
import ReactPlaceholder from "react-placeholder"
import { sentenceCase } from "text-case"
import { useTranslation } from "react-i18next"
import mixpanel from "mixpanel-browser"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import { MenuItem, Select } from "@mui/material"
import { useDimensionFilter, useDimensionOutline, useSetMode } from "./hooks"
import { buildAnalyticsEventName } from "../../util/user-monitoring"
import {
  DATA_MODE,
  DIMENSION_PRICE,
  DIMENSION_PRODUCT,
  GRID_MODE,
} from "../../ducks/pages/assortment-analysis/constants"

const analyticsEventName = buildAnalyticsEventName({
  feature: "Assortment Analysis",
  target: "Dimension",
  userAction: "Selected",
})

const selectorPlaceholder = (
  <ReactPlaceholder
    className="rounded"
    showLoadingAnimation
    style={{ width: 256, height: 32, margin: "0" }}
    type="rect"
  />
)

function AssortmentAnalysisDimensionSelector() {
  const {
    data: dimensions,
    isError,
    isIdle,
    isFetching,
    isSuccess,
  } = useDimensionOutline()
  const { dimension, setDimension } = useDimensionFilter()
  const { mode } = useSetMode()
  const { t } = useTranslation()

  const dims = useMemo(
    () =>
      dimensions?.filter((d) => {
        if (mode !== DATA_MODE && d === DIMENSION_PRODUCT) {
          return false
        }
        return true
      }),
    [dimensions, mode],
  )

  if (isIdle || isFetching) {
    return selectorPlaceholder
  }

  if (isError) {
    return null
  }

  if (dimension === DIMENSION_PRODUCT && mode === GRID_MODE) {
    setDimension({ dimension: DIMENSION_PRICE })
  }

  const mapDimension = (dim) => {
    if (dim === DIMENSION_PRODUCT) {
      return t("Product")
    }

    return t(dim)
  }

  const handleOptionSelection = ({ target }) => {
    mixpanel.track(analyticsEventName)
    setDimension({ dimension: target.value })
  }

  if (isSuccess && dims) {
    return (
      <Select
        fullWidth
        IconComponent={ExpandMoreRoundedIcon}
        onChange={handleOptionSelection}
        size="small"
        sx={{
          "&.MuiOutlinedInput-root": {
            "& fieldset": {
              border: 0,
              borderColor: "transparent",
              boxShadow: "none",
            },
            "&:hover fieldset": {
              border: 0,
              borderColor: "transparent",
              boxShadow: "none",
            },
            "&.Mui-focused fieldset": {
              border: 0,
              borderColor: "transparent",
              boxShadow: "none",
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
            borderColor: "transparent",
            boxShadow: "none",
          },
          boxShadow: "none",
          minWidth: 264,
        }}
        value={dimension}
      >
        {dims.map((opt) => (
          <MenuItem key={opt} value={opt}>
            {sentenceCase(mapDimension(opt))}
          </MenuItem>
        ))}
      </Select>
    )
  }

  return null
}

AssortmentAnalysisDimensionSelector.propTypes = {}
AssortmentAnalysisDimensionSelector.defaultProps = {}

export { AssortmentAnalysisDimensionSelector }
