import React, { useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { debounce as dbnc } from "lodash"
import Button from "@mui/material/Button"
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded"
import { Box, Container, TextField, Typography } from "@mui/material"
import { useStepFilter } from "./hooks"
import { Slideover } from "../ui"
import { STEP_PRICE } from "../../ducks/pages/assortment-analysis/constants"

const debounce = (func, wait = 500) => dbnc(func, wait)()

function AssortmentAnalysisSettings({ ...props }) {
  const { t } = useTranslation()
  const { step, setStep } = useStepFilter()
  const [open, isOpen] = useState(false)
  const [currStep, setStepState] = useState(step)

  const handleOpen = () => {
    isOpen(true)
  }

  const handleClose = () => {
    isOpen(false)
  }

  const handleStepChange = ({ target }) => {
    setStepState(target.value)
    debounce(() => setStep({ step: target.value }), 600)
  }

  return (
    <>
      <Box display="flex" gap={3}>
        <Button
          {...props}
          size="small"
          onClick={handleOpen}
          startIcon={<SettingsRoundedIcon size={16} />}
          data-testid="assortment-settings-button"
        >
          {t("Settings")}
        </Button>
      </Box>
      <Slideover
        title={t("Settings")}
        open={open}
        onClose={handleClose}
        data-testid="assortment-settings-slideover"
      >
        <Box>
          <Container sx={{ px: "16px !important", py: 2 }}>
            <Typography variant="body2">{t("General")}</Typography>
          </Container>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              marginTop: 5,
              px: 2,
            }}
          >
            <Typography variant="body2">{t("Price steps")}</Typography>
            <TextField
              size="small"
              value={currStep}
              placeholder={STEP_PRICE}
              onChange={handleStepChange}
              min="0"
              step="0.5"
              type="number"
              sx={{
                textAlign: "right",
                width: "75px",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0, step: 0.5 },
              }}
            />
          </Box>
        </Box>
      </Slideover>
    </>
  )
}

AssortmentAnalysisSettings.propTypes = {
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
}
AssortmentAnalysisSettings.defaultProps = {
  variant: "outlined",
}

export { AssortmentAnalysisSettings }
