import React, { useCallback, useState } from "react"
import { Box, Divider, useTheme } from "@mui/material"

import styles from "./AssortmentAnalysisToolbar.module.scss"
import { AssortmentAnalysisControl } from "./AssortmentAnalysisControl"
import { AssortmentAnalysisProductSegmentAutocomplete } from "./AssortmentAnalysisProductSegmentAutocomplete"
import { AssortmentAnalysisDimensionSelector } from "./AssortmentAnalysisDimensionSelector"
import { AssortmentAnalysisExportButton } from "./AssortmentAnalysisExportButton"
import { AssortmentAnalysisExportCategoryButton } from "./AssortmentAnalysisExportCategoryButton"
import { AssortmentAnalysisFilters } from "./AssortmentAnalysisFilters"
import { AssortmentAnalysisModeButtons } from "./AssortmentAnalysisModeButtons"
import { AssortmentAnalysisSettings } from "./AssortmentAnalysisSettings"
import { AssortmentAnalysisTotal } from "./AssortmentAnalysisTotal"
import { AssortmentAnalysisFiltersReset } from "./AssortmentAnalysisFiltersReset"

function AssortmentAnalysisToolbar() {
  const theme = useTheme()
  const [borderVisible, setBorderVisible] = useState({
    total: false,
  })

  const handleToggle = useCallback((control, show) => {
    setBorderVisible((prev) => ({
      ...prev,
      [control]: show,
    }))
  }, [])

  const handleTotalToggle = useCallback(
    (show) => {
      if (show === borderVisible.total) {
        return
      }
      handleToggle("total", show)
    },
    [borderVisible.total, handleToggle],
  )

  return (
    <Box
      className={styles["toolbar-root"]}
      data-testid="matches-overview-toolbar"
      sx={{
        p: 2,
      }}
    >
      <Box
        className={styles["control-list"]}
        sx={{
          border: "1px solid",
          borderColor: theme.palette.divider,
          justifyContent: "start",
          width: "fit-content",
        }}
      >
        <AssortmentAnalysisControl justify="start">
          <AssortmentAnalysisProductSegmentAutocomplete />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl>
          <AssortmentAnalysisDimensionSelector />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl justify="end" border={false}>
          <AssortmentAnalysisModeButtons />
        </AssortmentAnalysisControl>
      </Box>
      <Box
        className={styles["control-list"]}
        sx={{ justifyContent: "start", py: 1, width: "100%" }}
      >
        <AssortmentAnalysisControl>
          <AssortmentAnalysisFilters />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl border={borderVisible.total}>
          <AssortmentAnalysisTotal />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl border={false}>
          <AssortmentAnalysisFiltersReset onToggleControl={handleTotalToggle} />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl grows justify="end" border={false}>
          <AssortmentAnalysisExportCategoryButton variant="text" />
          <Divider orientation="vertical" sx={{ height: "50%", mx: 1 }} />
          <AssortmentAnalysisExportButton variant="text" />
          <Divider orientation="vertical" sx={{ height: "50%", mx: 1 }} />
          <AssortmentAnalysisSettings variant="text" />
        </AssortmentAnalysisControl>
      </Box>
    </Box>
  )
}

AssortmentAnalysisToolbar.propTypes = {}

AssortmentAnalysisToolbar.defaultProps = {}

export default AssortmentAnalysisToolbar
