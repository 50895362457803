import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"
import AppPropTypes from "../../prop-shapes"

import styles from "./AssortmentAnalysisControl.module.scss"

function AssortmentAnalysisControl({
  children,
  border,
  grows,
  icon,
  iconSlot,
  justify,
  padding,
}) {
  return (
    <li
      className={clsx(
        styles.control,
        { [styles.border]: border },
        { [styles.grow]: grows },
        { [styles.padding]: padding },
        styles[justify],
      )}
    >
      <span className={styles["slot-start"]}>{iconSlot === "start" && icon}</span>
      {children}
      <span className={styles["slot-end"]}>{iconSlot === "end" && icon}</span>
    </li>
  )
}

AssortmentAnalysisControl.propTypes = {
  border: PropTypes.bool,
  children: AppPropTypes.childrenNodes.isRequired,
  grows: PropTypes.bool,
  icon: AppPropTypes.childrenElements,
  iconSlot: PropTypes.oneOf(["start", "end"]),
  justify: PropTypes.oneOf(["start", "center", "end"]),
  padding: PropTypes.bool,
}

AssortmentAnalysisControl.defaultProps = {
  border: true,
  grows: false,
  icon: null,
  iconSlot: "end",
  justify: "center",
  padding: false,
}

export { AssortmentAnalysisControl }
