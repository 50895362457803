import queryString from "query-string"
import { ofType } from "redux-observable"
import { debounceTime, filter, map, tap } from "rxjs/operators"
import { get } from "lodash"
import { LOCATION_CHANGE, replace } from "connected-react-router"
import * as APIService from "../../../api/service"
import { setSearchParams } from "../../../util/redux-helpers"

import * as Actions from "./action-types"

import { isInPage, isUserAuthenticated, withParent } from "../../../util/epics"
import {
  enqueueGetCustomerAssortment,
  enqueueGetCustomerAssortmentFilterOptions,
  enqueueGetCustomerAssortmentOutline,
  getCustomerAssortment,
  getCustomerAssortmentFilterOptions,
  getCustomerAssortmentOutline,
  readAssortmentAnalysisPageStateFromPreviousState,
  readAssortmentAnalysisPageStateFromURL,
} from "./action-creators"
import { buildEpic } from "../../../util/redux-observable-helpers"
import {
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY,
  ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_SELECTED_FILTER_OPTIONS_KEY,
  ASSORTMENT_ANALYSIS_PAGE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY,
  DIMENSION_PRICE,
  ASSORTMENT_ANALYSIS_PAGE_STEP_KEY,
  STEP_PRICE,
  GRID_MODE,
  ASSORTMENT_ANALYSIS_PAGE_MODE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_ALL_KEY,
} from "./constants"
import {
  QP_DIMENSION,
  QP_PRODUCT_GROUP,
  QP_SORT,
  QP_STEP,
  QP_MODE,
  QP_ALL,
} from "../../../util/query-param"
import { assortmentToolRoutes } from "../../../routes/assortment-tool/AssortmentTool"
import { arrayWithElementsOrUndefined } from "../../../util/array"
import { SORT_ASC, createSortProperty } from "../../../util/sorting"

const DEBOUNCE_MILLISECONDS = 50
const one = (value) => value || undefined
const many = arrayWithElementsOrUndefined

function getFiltersFromState(state) {
  return get(state, [
    "pages",
    ASSORTMENT_ANALYSIS_PAGE_KEY,
    ASSORTMENT_ANALYSIS_PAGE_FILTERS_KEY,
  ])
}

function getSortsFromState(state) {
  return get(state, [
    "pages",
    ASSORTMENT_ANALYSIS_PAGE_KEY,
    ASSORTMENT_ANALYSIS_PAGE_SORTS_KEY,
  ])
}

function getModeFromState(state) {
  return get(state, [
    "pages",
    ASSORTMENT_ANALYSIS_PAGE_KEY,
    ASSORTMENT_ANALYSIS_PAGE_MODE_KEY,
  ])
}

function getSortsArrayFromState(state) {
  return Object.entries(getSortsFromState(state)).map(([property, direction]) =>
    createSortProperty(property, direction),
  )
}

function getFilterOptionsFromState(state) {
  const options = get(state, [
    "pages",
    ASSORTMENT_ANALYSIS_PAGE_KEY,
    ASSORTMENT_ANALYSIS_PAGE_SELECTED_FILTER_OPTIONS_KEY,
  ])
  return options
}

function getSeletedFilterOptionsFromState(state) {
  const options = get(state, [
    "pages",
    ASSORTMENT_ANALYSIS_PAGE_KEY,
    ASSORTMENT_ANALYSIS_PAGE_SELECTED_FILTER_OPTIONS_KEY,
  ])
  if (options.filterOptions) {
    return options.filterOptions
  }
  return options || {}
}

function isVisitingAssortmentAnalysis(state$) {
  return (
    isUserAuthenticated(state$.value) &&
    isInPage(assortmentToolRoutes.analysis, state$.value)
  )
}

function parametrizeState(state) {
  const filters = getFiltersFromState(state)
  const dimension = filters[ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]
  const productGroup = filters[ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY]
  const step = filters[ASSORTMENT_ANALYSIS_PAGE_STEP_KEY]
  const mode = getModeFromState(state) || GRID_MODE
  const all = filters[ASSORTMENT_ANALYSIS_PAGE_ALL_KEY] || false

  const sorts = getSortsArrayFromState(state)

  const filterOptions = getSeletedFilterOptionsFromState(state)

  const parameters = {
    [QP_DIMENSION]: one(dimension),
    [QP_PRODUCT_GROUP]: one(productGroup),
    [QP_SORT]: many(sorts),
    [QP_STEP]: one(step),
    [QP_MODE]: one(mode),
    [QP_ALL]: one(all),
  }

  Object.keys(filterOptions).forEach((opt) => {
    parameters[opt] = many(filterOptions[opt])
  })

  return parameters
}

/* -------------------------------------------------------------------------- */
/*                     url bi-directional synchronisation                     */
/* -------------------------------------------------------------------------- */

const triggerAssortmentAnalysisStoreUpdateFromHistoryEvent = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    filter(() => isVisitingAssortmentAnalysis(state$)),
    map((action) => {
      const currentLocation = state$.value.router.location
      const previousPath = state$.value.app.prevLocation.pathname

      const { analysis } = assortmentToolRoutes

      /** Assortment Analysis does not have sibling pages to rehydrate from like Match Overview does */
      const canRehydrate = false

      if (canRehydrate) {
        return readAssortmentAnalysisPageStateFromPreviousState()
      }

      const comesFromDifferentPage = previousPath && !previousPath.startsWith(analysis)
      const comesFromURL = action.payload.isFirstRendering
      const canApplyDefaults =
        (comesFromURL || comesFromDifferentPage) && currentLocation.search === ""

      const defaultFilters = canApplyDefaults
        ? {
            [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: DIMENSION_PRICE,
            [ASSORTMENT_ANALYSIS_PAGE_STEP_KEY]: STEP_PRICE,
            [ASSORTMENT_ANALYSIS_PAGE_ALL_KEY]: false,
          }
        : {}

      const defaultSorts = canApplyDefaults
        ? {
            [ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]: SORT_ASC,
          }
        : {}

      const defaultFilterOptions = canApplyDefaults
        ? {
            [ASSORTMENT_ANALYSIS_PAGE_FILTER_OPTIONS_KEY]: {},
          }
        : {}

      return readAssortmentAnalysisPageStateFromURL({
        defaultFilters,
        defaultSorts,
        defaultFilterOptions,
        defaultMode: GRID_MODE,
      })
    }),
  )

const triggerAssortmentAnalysisSearchParamsUpdateFromStateRehydration = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(Actions.ASSORTMENT_ANALYSIS_REHYDRATE_FROM_PREVIOUS_STATE),
    filter(() => isVisitingAssortmentAnalysis(state$)),
    map(() =>
      replace({
        pathname: state$.value.router.location.pathname,
        search: queryString.stringify(parametrizeState(state$.value)),
      }),
    ),
  )

const triggerAssortmentAnalysisSearchParamsUpdateFromUserInteraction = (
  action$,
  state$,
) =>
  action$.pipe(
    ofType(
      Actions.ASSORTMENT_ANALYSIS_SET_DIMENSION_FILTER,
      Actions.ASSORTMENT_ANALYSIS_SET_DIMENSION_SORT,
      Actions.ASSORTMENT_ANALYSIS_SET_PAGE,
      Actions.ASSORTMENT_ANALYSIS_SET_PAGE_SIZE,
      Actions.ASSORTMENT_ANALYSIS_SET_PRODUCT_GROUP_FILTER,
      Actions.ASSORTMENT_ANALYSIS_SET_FILTER_OPTIONS,
      Actions.ASSORTMENT_ANALYSIS_SET_STEP_FILTER,
      Actions.ASSORTMENT_ANALYSIS_SET_MODE,
    ),
    filter(() => isVisitingAssortmentAnalysis(state$)),
    tap(() => setSearchParams(parametrizeState(state$.value))),
    map((action) => withParent(action, enqueueGetCustomerAssortment())),
  )

/* -------------------------------------------------------------------------- */
/*                          assortment analysis data                          */
/* -------------------------------------------------------------------------- */

function triggerGetCustomerAssortmentFromAssortmentAnalysisFromHistoryEventEpic(
  action$,
  state$,
) {
  return action$.pipe(
    ofType(Actions.ASSORTMENT_ANALISYS_READ_URL_STATE),
    filter(() => isVisitingAssortmentAnalysis(state$)),
    map((action) => withParent(action, enqueueGetCustomerAssortment())),
  )
}

function triggerGetCustomerAssortmentFromAssortmentAnalysisOnDemandEpic(
  action$,
  state$,
) {
  return action$.pipe(
    ofType(Actions.ASSORTMENT_ANALYSIS_TRIGGER_GET_CUSTOMER_ASSORTMENT),
    filter(() => isVisitingAssortmentAnalysis(state$)),
    map((action) => withParent(action, enqueueGetCustomerAssortment())),
  )
}

function enqueueGetCustomerAssortmentFromAssortmentAnalysisPageEpic(action$) {
  return action$.pipe(
    ofType(Actions.ASSORTMENT_ANALYSIS_ENQUEUE_GET_CUSTOMER_ASSORTMENT),
    debounceTime(DEBOUNCE_MILLISECONDS),
    map((action) => withParent(action, getCustomerAssortment())),
  )
}

const getCustomerAssortmentFromAssortmentAnalysisPageEpic = buildEpic(
  Actions.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT,
  (_action, state) => {
    const filters = getFiltersFromState(state)
    const sort = getSortsArrayFromState(state)
    const filterOptions = getFilterOptionsFromState(state)

    const dimension = filters[ASSORTMENT_ANALYSIS_PAGE_FILTERS_DIMENSION_KEY]
    const productGroupId = filters[ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY]
    const step = filters[ASSORTMENT_ANALYSIS_PAGE_STEP_KEY]
    const all = filters[ASSORTMENT_ANALYSIS_PAGE_ALL_KEY]

    return APIService.fetchCustomerAssortmentAnalysis({
      dimension,
      productGroupId,
      sort,
      step,
      filterOptions,
      all,
    })
  },
  undefined,
  true,
)

/* -------------------------------------------------------------------------- */
/*                         assortment analysis filters                        */
/* -------------------------------------------------------------------------- */

function triggerGetCustomerAssortmentFilterOptionsFromAssortmentAnalysisPageEpic(
  action$,
) {
  return action$.pipe(
    ofType(
      Actions.ASSORTMENT_ANALYSIS_TRIGGER_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS,
      Actions.ASSORTMENT_ANALYSIS_SET_PRODUCT_GROUP_FILTER,
    ),
    map((action) => withParent(action, enqueueGetCustomerAssortmentFilterOptions())),
  )
}

function enqueueGetCustomerAssortmentFilterOptionsFromAssortmentAnalysisPageEpic(
  action$,
) {
  return action$.pipe(
    ofType(Actions.ASSORTMENT_ANALYSIS_ENQUEUE_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS),
    debounceTime(DEBOUNCE_MILLISECONDS),
    map((action) => withParent(action, getCustomerAssortmentFilterOptions())),
  )
}

const getCustomerAssortmentFromAssortmentFilterOptionsAnalysisPageEpic = buildEpic(
  Actions.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_FILTER_OPTIONS,
  (_action, state) => {
    const filters = getFiltersFromState(state)
    const productGroupId = filters[ASSORTMENT_ANALYSIS_PAGE_FILTERS_PRODUCT_GROUP_KEY]

    return APIService.fetchCustomerAssortmentFilterOptions(productGroupId)
  },
  undefined,
  true,
)

/* -------------------------------------------------------------------------- */
/*                         assortment analysis outline                        */
/* -------------------------------------------------------------------------- */

function triggerGetCustomerAssortmentOutlineFromAssortmentAnalysisPageEpic(action$) {
  return action$.pipe(
    ofType(Actions.ASSORTMENT_ANALYSIS_TRIGGER_GET_CUSTOMER_ASSORTMENT_OUTLINE),
    map((action) => withParent(action, enqueueGetCustomerAssortmentOutline())),
  )
}

function enqueueGetCustomerAssortmentOutlineFromAssortmentAnalysisPageEpic(action$) {
  return action$.pipe(
    ofType(Actions.ASSORTMENT_ANALYSIS_TRIGGER_GET_CUSTOMER_ASSORTMENT_OUTLINE),
    debounceTime(DEBOUNCE_MILLISECONDS),
    map((action) => withParent(action, getCustomerAssortmentOutline())),
  )
}

const getCustomerAssortmentFromAssortmentOutlineAnalysisPageEpic = buildEpic(
  Actions.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_OUTLINE,
  (_action, _state) => APIService.fetchCustomerAssortmentOutline(),
  undefined,
  true,
)

/* -------------------------------------------------------------------------- */
/*                         assortment analysis export                         */
/* -------------------------------------------------------------------------- */

const getCustomerAssortmentExportFromAssortmentAnalysisPageEpic = buildEpic(
  Actions.ASSORTMENT_ANALYSIS_GET_CUSTOMER_ASSORTMENT_EXPORT,
  (action, _state) => {
    const { categoryId } = action
    return APIService.fetchCustomerAssortmentAnalysisExport(categoryId)
  },
  undefined,
  true,
)

/* -------------------------------------------------------------------------- */
/*                                   exports                                  */
/* -------------------------------------------------------------------------- */

export default [
  enqueueGetCustomerAssortmentFromAssortmentAnalysisPageEpic,
  enqueueGetCustomerAssortmentFilterOptionsFromAssortmentAnalysisPageEpic,
  enqueueGetCustomerAssortmentOutlineFromAssortmentAnalysisPageEpic,
  getCustomerAssortmentFromAssortmentAnalysisPageEpic,
  getCustomerAssortmentExportFromAssortmentAnalysisPageEpic,
  getCustomerAssortmentFromAssortmentFilterOptionsAnalysisPageEpic,
  getCustomerAssortmentFromAssortmentOutlineAnalysisPageEpic,
  triggerAssortmentAnalysisStoreUpdateFromHistoryEvent,
  triggerAssortmentAnalysisSearchParamsUpdateFromStateRehydration,
  triggerAssortmentAnalysisSearchParamsUpdateFromUserInteraction,
  triggerGetCustomerAssortmentFromAssortmentAnalysisFromHistoryEventEpic,
  triggerGetCustomerAssortmentFromAssortmentAnalysisOnDemandEpic,
  triggerGetCustomerAssortmentFilterOptionsFromAssortmentAnalysisPageEpic,
  triggerGetCustomerAssortmentOutlineFromAssortmentAnalysisPageEpic,
]
